
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    label: { type: String, required: true },
    backgroundColor: { type: String, required: false, default: 'bg-app-primary-1-red--fire-brick' },
    hoverBackgroundColor: { type: String, required: false, default: 'hover:bg-black' },
    flush: { type: Boolean, required: false, default: false },
    url: { type: [Object, String], required: false, default: undefined },
    textColor: { type: String, required: false, default: 'text-white' },
    hoverTextColor: { type: String, required: false, default: 'text-white' },
  },
  setup(props, { emit }) {
    const onClick = (event) => emit('click', event)

    return {
      onClick,
    }
  },
})
