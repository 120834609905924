
import { defineComponent, ref, useFetch, useStore } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    uri: { type: String, required: true },
  },
  setup(props, { emit }) {
    const store = useStore()
    const state = ref('loading')
    const page = ref(null)

    useFetch(async () => {
      try {
        page.value = await store.dispatch('page/fetchUri', { uri: props.uri })
        state.value = page.value ? 'ready' : 'error'
      } catch (error) {
        emit('error', { uri: props.uri, error })
        state.value = 'error'
      }
    })

    return {
      page,
      state,
    }
  },
})
