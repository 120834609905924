
import { defineComponent } from '@nuxtjs/composition-api'
import get from 'lodash/get'
import { setupPrepareTixSale } from '~/assets/composition/tix'

export default defineComponent({
  props: {
    uris: { type: Array, required: true },
    title: { type: String, required: false, default: undefined },
    buttonLabel: { type: String, required: false, default: undefined },
    buttonUrl: { type: String, required: false, default: undefined },
    colorTheme: { type: String, required: false, default: undefined },
  },
  setup() {
    return {
      get,
      prepareTixSale: setupPrepareTixSale(),
    }
  },
})
