
import { defineComponent } from '@nuxtjs/composition-api'
const validTargets = ['normal', 'wide']

export default defineComponent({
  props: {
    image: { type: Object, required: true },
    imageLarge: { type: Object, required: true },
    size: { type: String, required: false, default: 'normal', validator: (value) => validTargets.includes(value) },
    subtitle: { type: String, required: false, default: '' },
  },
})
