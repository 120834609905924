
import { defineComponent } from '@nuxtjs/composition-api'
import get from 'lodash/get'
import { setupDynamicContentItems } from '~/assets/composition/setup-dynamic-content-items'

export default defineComponent({
  props: {
    items: { type: Array, required: true },
    backgroundColor: { type: String, required: false, default: 'bg-white' },
    colorTheme: { type: String, required: false, default: undefined },
    mobileTheme: { type: String, required: false, default: undefined },
    matrixClasses: { type: String, required: false, default: 'mb-10 md:mb-16' },
  },
  setup(props) {
    const { groupedItems } = setupDynamicContentItems(props.items)
    return { get, groupedItems }
  },
})
