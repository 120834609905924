import { render, staticRenderFns } from "./MatrixDiscoveries.vue?vue&type=template&id=70ab58ac"
import script from "./MatrixDiscoveries.vue?vue&type=script&lang=js"
export * from "./MatrixDiscoveries.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculeButton: require('/home/userapp/components/Molecule/Button/Button.vue').default,MoleculeDiscoveryCarousel: require('/home/userapp/components/Molecule/DiscoveryCarousel.vue').default})
