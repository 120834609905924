
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    description: { type: String, required: true },
    backgroundColor: { type: String, required: false, default: 'bg-white' },
    colorTheme: { type: String, required: false, default: undefined },
    mobileTheme: { type: String, required: false, default: undefined },
  },
})
