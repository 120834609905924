
import { defineComponent, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    label: { type: String, required: false, default: undefined },
    when: { type: Date, required: false, default: undefined },
    description: { type: String, required: false, default: undefined },
    colorTheme: { type: String, required: false, default: undefined },
    open: { type: Boolean, required: false, default: false },
  },
  setup(props) {
    const isOpen = ref(props.open)
    return { isOpen }
  },
})
