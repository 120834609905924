
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    title: { type: String, required: true },
    topLabel: { type: String, required: false, default: undefined },
    subLabel: { type: String, required: false, default: undefined },
    icon: { type: String, required: false, default: undefined },
    image: { type: Object, required: false, default: undefined },
    buttonTitle: { type: String, required: true },
    buttonUrl: { type: String, required: true },
    theme: { type: String, required: true },
    type: { type: String, required: false, default: undefined },
  },
})
