
import { defineComponent, ref } from '@nuxtjs/composition-api'
import { setupGtmPromoClick, setupGtmPromoView } from '~/assets/composition/setup-gtm'

export default defineComponent({
  props: {
    title: { type: String, required: true },
    topLabel: { type: String, required: false, default: '' },
    subLabel: { type: String, required: false, default: '' },
    icon: { type: String, required: false, default: '' },
    image: { type: Object, required: false, default: () => undefined },
    buttonTitle: { type: String, required: false, default: '' },
    buttonUrl: { type: String, required: false, default: '' },
    theme: { type: String, required: true },
  },
  setup(props) {
    const el = ref()
    const getPosition = () => (el.value?.getBoundingClientRect()?.top || 0) + (window.pageYOffset || 0)

    const promoView = setupGtmPromoView()
    const onViewport = () =>
      promoView([
        {
          name: props.title,
          creative: [props.topLabel, props.subLabel].join(' | '),
          position: getPosition(),
        },
      ])

    const promoClick = setupGtmPromoClick()
    const onClick = () =>
      promoClick([
        {
          name: props.title,
          creative: [props.topLabel, props.subLabel].join(' | '),
          position: getPosition(),
        },
      ])

    return {
      el,
      onClick,
      onViewport,
    }
  },
})
