
import { defineComponent, ref, useContext } from '@nuxtjs/composition-api'
import { setupTix } from '~/assets/composition/tix'
import { setupTranslationPrefixer } from '~/assets/util'

export default defineComponent({
  props: {
    label: { type: String, required: false, default: undefined },
    description: { type: String, required: false, default: undefined },
    successText: { type: String, required: false, default: undefined },
    invalidText: { type: String, required: false, default: undefined },
  },
  setup() {
    const { $config } = useContext()
    const { tixUser } = setupTix()
    const { t_ } = setupTranslationPrefixer('MatrixVriendenLoterijVipCard')
    const formErrors = ref([])
    const successMessages = ref([])
    const submitVipCardHandler = async (data) => {
      // Clear errors and successes before saving
      formErrors.value = []
      successMessages.value = []

      // A Tix user is required, because we will immediately add a tag when the VIP is valid
      if (!tixUser.value) {
        formErrors.value = [t_('no-tix-user-found')]
        return
      }

      const response = await fetch(`${$config.bridgeSiteUrl}/api/vip-card/${data.number}/${tixUser.value.id}/${tixUser.value.email}/validate`, {
        headers: {
          Accept: 'application/json',
        },
      })

      if ((await response.json())?.success) {
        successMessages.value = [t_('generic-success')]
      } else {
        formErrors.value = [t_('generic-error')]
      }
    }

    return {
      formErrors,
      submitVipCardHandler,
      successMessages,
      t_,
      tixUser,
    }
  },
})
