
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    breadcrumbs: { type: Array, required: true },
    colorTheme: { type: String, required: false, default: undefined },
    page: { type: Object, required: true },
  },
})
