
import { defineComponent, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    label: { type: String, required: false, default: undefined },
    widgetHeight: { type: Number, required: false, default: 100 },
    widgetUrl: { type: String, required: true },
    colorTheme: { type: String, required: false, default: undefined },
  },
  setup({ widgetUrl }) {
    const allowAttribute = ref(undefined)

    // create separate function if more logic is needed
    if (widgetUrl.includes('spotify.com')) {
      allowAttribute.value = 'encrypted-media'
    }

    return {
      allowAttribute,
    }
  },
})
