
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    items: { type: Array, required: true },
    title: { type: String, required: false, default: undefined },
    buttonLabel: { type: String, required: false, default: undefined },
    buttonUrl: { type: String, required: false, default: undefined },
    colorTheme: { type: String, required: false, default: undefined },
  },
})
