import { render, staticRenderFns } from "./CardEventAndSeries.vue?vue&type=template&id=35249cec&scoped=true"
import script from "./CardEventAndSeries.vue?vue&type=script&lang=js"
export * from "./CardEventAndSeries.vue?vue&type=script&lang=js"
import style0 from "./CardEventAndSeries.vue?vue&type=style&index=0&id=35249cec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35249cec",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculePicture: require('/home/userapp/components/Molecule/Picture.vue').default,AtomAnimationsCircleLoader: require('/home/userapp/components/Atom/Animations/AnimationsCircleLoader.vue').default,MoleculeButton: require('/home/userapp/components/Molecule/Button/Button.vue').default,AtomIcon: require('/home/userapp/components/Atom/Icon.vue').default,AtomLink: require('/home/userapp/components/Atom/Link.vue').default,AtomIconBookmarkFilled: require('/home/userapp/components/Atom/Icon/IconBookmarkFilled.vue').default,AtomIconBookmark: require('/home/userapp/components/Atom/Icon/IconBookmark.vue').default})
