
import { defineComponent } from '@nuxtjs/composition-api'
import breakpointsList from '~/config/carousel'

export default defineComponent({
  props: {
    label: { type: String, required: true },
    entries: { type: Array, required: true },
    buttonTitle: { type: String, required: false, default: undefined },
    buttonUrl: { type: String, required: false, default: undefined },
  },
  data() {
    return {
      breakpointsList,
    }
  },
})
