
import { computed, defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    items: { type: Array, required: true },
    colorTheme: { type: String, required: false, default: undefined },
  },
  setup(props) {
    const groupedList = computed(() => {
      const newGroupedList = []
      let pointer = -1
      props.items.forEach((item) => {
        if (item.foldoutTitle || newGroupedList.length === 0) {
          pointer++
          newGroupedList[pointer] = {
            title: item.foldoutTitle ? item.foldoutTitle : null,
            items: [],
          }
        }
        newGroupedList[pointer].items.push(item)
      })
      return newGroupedList
    })

    return {
      groupedList,
    }
  },
})
