
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    items: { type: Array, required: true },
    hideCaptions: { type: Array, required: false, default: () => [] },
  },
  data() {
    return {
      slickOptions: {
        arrows: true,
        slidesToShow: 1,
        responsive: [
          {
            breakpoint: 1024, // till 1024px
            settings: {
              slidesToShow: 1,
              arrows: false,
            },
          },
        ],
      },
    }
  },
})
