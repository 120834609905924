
import { defineComponent, ref, useContext, useFetch } from '@nuxtjs/composition-api'
import { toCESTDateString } from '~/assets/util'
import { toHour } from '@/assets/date'

export default defineComponent({
  props: {
    buttonLabel: { type: String, required: false, default: undefined },
    buttonUrl: { type: String, required: false, default: undefined },
    colorTheme: { type: String, required: false, default: undefined },
    composers: { type: Array, required: false, default: undefined },
    eventTypes: { type: Array, required: false, default: undefined },
    genres: { type: Array, required: false, default: undefined },
    instruments: { type: Array, required: false, default: undefined },
    keywords: { type: String, required: false, default: undefined },
    maximumItems: { type: Number, required: false, default: 15 },
    organizers: { type: Array, required: false, default: undefined },
    rooms: { type: Array, required: false, default: undefined },
    searchFromDate: { type: String, required: false, default: undefined },
    specials: { type: Array, required: false, default: undefined },
    tags: { type: Array, required: false, default: undefined },
    title: { type: String, required: false, default: undefined },
  },
  setup(props) {
    const uris = ref([])

    const { app, $graphqlFetch } = useContext()
    useFetch(async () => {
      const { elasticSearch } = await $graphqlFetch({
        token: 'elasticsearch',
        query: `
          query matrixSearchEvents(
            $site: [String]
            $limit: Int
            $gteDate: [String]
            $search: String
            $eventTypes: [String]
            $genres: [String]
            $organizers: [String]
            $composers: [String]
            $instruments: [String]
            $rooms: [String]
            $tags: [String]
            $specials: [String]
            $importIconcertStatus: [String]
          ) {
            elasticSearch(
              section: "event"
              site: $site
              eventDateRange: $gteDate
              search: $search
              eventTypeFacet: $eventTypes
              genresFacet: $genres
              organizerFacet: $organizers
              composersFacet: $composers
              instrumentsFacet: $instruments
              roomFacet: $rooms
              tagFacet: $tags
              specialsFacet: $specials
              importIconcertStatusFacet: $importIconcertStatus
              orderBy: ["eventDate ASC", "room ASC"]
            ) {
              hits(limit: $limit) {
                uri
              }
            }
          }
        `,
        variables: {
          site: `${app.i18n.locale}Default`,
          limit: props.maximumItems,
          gteDate: `gte ${toCESTDateString(props.searchFromDate, toCESTDateString(toHour('now')))}`,
          search: props.keywords,
          eventTypes: props.eventTypes?.map((value) => value.title) || [],
          genres: props.genres?.map((value) => value.title) || [],
          organizers: props.organizers?.map((value) => value.title) || [],
          composers: props.composers?.map((value) => value.title) || [],
          instruments: props.instruments?.map((value) => value.title) || [],
          rooms: props.rooms?.map((value) => value.title) || [],
          tags: props.tags?.map((value) => value.title) || [],
          specials: props.specials?.map((value) => value.title) || [],
          importIconcertStatus: ['1e Optie', 'Bevestigd', 'Contract uit', 'Corona', 'Voor brochure'],
        },
      })

      uris.value = elasticSearch.hits.map(({ uri }) => uri)
    })

    return {
      uris,
    }
  },
})
