
import smoothReflow from 'vue-smooth-reflow'

export default {
  mixins: [smoothReflow],
  props: {
    tag: { type: String, default: 'div' },
    options: { type: Object, default: () => ({}) },
  },
  mounted() {
    this.$smoothReflow(this.options)
  },
}
