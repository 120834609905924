
import { defineComponent } from '@nuxtjs/composition-api'
import breakpointsList from '~/config/carousel'

export default defineComponent({
  props: {
    label: { type: String, required: false, default: undefined },
    slidesVisible: { type: Number, required: false, default: 2 },
    portrait: { type: Boolean, required: false, default: false },
    entries: { type: Array, required: true },
    buttonTitle: { type: String, required: false, default: undefined },
    buttonUrl: { type: String, required: false, default: undefined },
    shelfBorder: { type: Boolean, required: false, default: true },
  },
  setup() {
    return { breakpointsList }
  },
})
