
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    url: { type: String, required: false, default: '/' },
    title: { type: String, required: false, default: 'Home' },
    whiteLyre: { type: Boolean, required: false, default: false },
    flush: { type: Boolean, required: false, default: false },
  },
})
