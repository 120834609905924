
import { defineComponent, useContext } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    breadcrumbs: { type: Array, required: true },
    backgroundColor: { type: String, required: false, default: undefined },
    flush: { type: Boolean, required: false, default: false },
    textColor: { type: String, required: false, default: undefined },
  },
  setup(props) {
    const { i18n } = useContext()

    const crumb = props.breadcrumbs.length > 1 ? props.breadcrumbs[props.breadcrumbs.length - 2] : undefined

    return {
      url: crumb?.url,
      label: crumb?.labelTemplate ? i18n.t(crumb.labelTemplate) : crumb?.label,
    }
  },
})
