import { render, staticRenderFns } from "./MatrixEvents.vue?vue&type=template&id=182934a9"
import script from "./MatrixEvents.vue?vue&type=script&lang=js"
export * from "./MatrixEvents.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculeCardEventAndSeries: require('/home/userapp/components/Molecule/Card/CardEventAndSeries.vue').default,OrganismLoaderFromUri: require('/home/userapp/components/Organism/Loader/LoaderFromUri.vue').default,MoleculeButton: require('/home/userapp/components/Molecule/Button/Button.vue').default})
