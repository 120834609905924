import kebabCase from 'lodash/kebabCase'
import { setupTranslationPrefixer } from '~/assets/util'

const concertStatusIcons = {
  // handle-name => icon
  // 'op-het-web': 'app-circled-exclamation-mark',
  geannuleerd: 'app-circled-exclamation-mark',
  verplaatst: 'app-circled-exclamation-mark',
}

/**
 * @param {String|null} status
 * @param {String} translationGroup
 * @returns {{concertStatusLabel: (String|undefined), concertStatusIcon: (String|undefined)}}
 */
export function setupConcertStatus(status, translationGroup) {
  const handle = status && status.length > 0 ? kebabCase(status) : undefined
  const { t_ } = setupTranslationPrefixer('iConcert', translationGroup)

  return {
    concertStatusLabel: concertStatusIcons[handle] ? t_(handle) : undefined,
    concertStatusIcon: concertStatusIcons[handle] ?? undefined,
  }
}
