import { render, staticRenderFns } from "./CampaignDetail.vue?vue&type=template&id=7d503f78"
import script from "./CampaignDetail.vue?vue&type=script&lang=js"
export * from "./CampaignDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculeButtonBreadcrumbBack: require('/home/userapp/components/Molecule/Button/ButtonBreadcrumbBack.vue').default,MoleculeButtonMobileLyre: require('/home/userapp/components/Molecule/Button/ButtonMobileLyre.vue').default,MoleculeMaskVisual: require('/home/userapp/components/Molecule/MaskVisual.vue').default,MoleculeButton: require('/home/userapp/components/Molecule/Button/Button.vue').default,OrganismPageSectionContent: require('/home/userapp/components/Organism/PageSectionContent.vue').default,MoleculeMatrix: require('/home/userapp/components/Molecule/Matrix/Matrix.vue').default,TemplateBase: require('/home/userapp/components/Template/Base.vue').default})
