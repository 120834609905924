import { render, staticRenderFns } from "./MatrixCollectionShelf.vue?vue&type=template&id=311b12b1"
import script from "./MatrixCollectionShelf.vue?vue&type=script&lang=js"
export * from "./MatrixCollectionShelf.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculeCardCollection: require('/home/userapp/components/Molecule/Card/CardCollection.vue').default,MoleculeShelf: require('/home/userapp/components/Molecule/Shelf.vue').default})
